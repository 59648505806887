<template>
  <div class="page-login-desktop">

    <div
        v-if="initLoading"
        class="pa-16 d-flex align-center justify-center">
      <v-progress-circular
          color="main"
          indeterminate
      />
    </div>


    <v-card flat v-else>
      <v-card-title class="d-flex align-center justify-space-between">
        <div class="text-body-1 font-weight-bold">
          ویرایش مجوز های دسترسی مدیران
        </div>
        <div style="width: 350px">
          <v-select
              hide-details
              label="انتخاب گروه دسترسی"
              outlined
              :items="roles"
              item-value="id"
              item-text="name"
              v-model="user.role"
          />
        </div>
      </v-card-title>

      <v-divider class="my-6"/>

      <v-card-text>
        <div class="w-100 d-flex align-center justify-space-between">
          <v-row>
            <v-col :cols="4" v-for="(item,index) in permissions"
                   :key="index"
            >
              <v-checkbox
                  :label="item.name"
                  inset
                  dense
                  hide-details
                  v-model="user.permissions"
                  :value="item.value"
              />
            </v-col>


            <v-col :cols="12" class="mt-6">
              <v-btn
                  @click="updatePermissions"
                  color="success" :loading="user.loading" large>
                ذخیره
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import {fetchPermissions, updatePermissions} from "@Newfiling/module-admin/src/api";

export default {
  name: 'DesktopLogin',

  data() {
    return {
      roles: [],
      initLoading: true,
      permissions: [],
      user: {
        loading: false,
        role: {},
        permissions: [],
      }
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست مدیران',
        disabled: false,
        href: '/panel/admins/list',
      },
      {
        text: 'مجوز های دسترسی',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.$nextTick(async () => {
      this.initLoading = true;
      await this.fetchPermissions();
      this.initLoading = false;
    })
  },

  methods: {
    async fetchPermissions() {
      try {
        const res = (await fetchPermissions())?.data;
        console.log({res})
        this.roles = res.userlevels.map((item, index) => {
          return {
            id: item.id,
            name: item.role,
            permissions: item.accessiblepagesTB.split('-'),
          }
        }).filter(item=>item.id>3);
        this.permissions = res.listofpages.map(item => {
          return {
            value: item.englishdescription,
            id: item.id,
            name: item.pagedescription
          }
        });
        this.user.role = this.roles[0].id;

      } catch (e) {
        console.log(e)
      }

    },

    async updatePermissions() {
      this.user.loading = true;
      try {
        await updatePermissions({
          Userlevel:this.user.role,
          Accessiblepages:this.user.permissions.join('-')
        });
        this.$toast.success('مجوز های دسترسی با موفقیت ویرایش شد.');
        await this.fetchPermissions();
      } catch (e) {
        console.log(e)
      }
      this.user.loading = false;

    }
  },

  watch: {
    'user.role'(value) {
      console.log({value})
      this.user.permissions = this.roles.find(item => item.id === value).permissions || [];
    }
  }

}
</script>
